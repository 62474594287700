<template>
  <div id="talent-dashboard">
    <v-app-bar app color="white" light flat>
      <v-app-bar-nav-icon
        v-if="!sidebarMenu&&!$device.mobile"
        @click.stop="sidebarMenu = !sidebarMenu"
      />
      <MobileNavBar v-if="$device.mobile" />
      <v-spacer />
      <NotificationButton />
      <v-menu offset-y class="flex-column" left>
        <template #activator="{ on, attrs }">
          <v-list>
            <v-list-item class="pa-0">
              <v-list-item-avatar class="rounded ma-0" size="47">
                <v-img
                  v-bind="attrs"
                  class="elevation-6"
                  alt="ProfileImg"
                  :src="profileImage"
                  v-on="on"
                />
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </template>
        <v-list>
          <v-list-item to="/talent/dashboard">
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item to="/account/settings">
            <v-list-item-title>{{ $t('account_settings') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <NavBarList :sidebar.sync="sidebarMenu" />
    <v-container class="pb-20 pt-10 dashboard-container grey" fluid>
      <v-row class="">
        <v-col md="2" cols="12" class="text-center pa-6 session-card-section ">
          <v-card-title class="text-h3 light-text pa-0 mt-5 availability">
            {{ $t('profile') }}
          </v-card-title>
        </v-col>
        <v-col md="8" cols="12" class="text-center pa-6 session-card-section ">
          <v-btn-toggle
            v-model="toggleBtnText"
            tile
            color="deep-purple accent-3"
            class="availability-toggler"
            group
          >
            <v-btn value="description" class="no-shadow">
              {{ $t('description') }}
            </v-btn>

            <v-btn value="media" class="no-shadow">
              {{ $t('media') }}
            </v-btn>

            <v-btn value="tags" class="no-shadow">
              {{ $t('tags') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row v-if="toggleBtnText === 'description'" class="mb-10">
        <v-col
          cols="md-8"
          style=" display: block;
                  margin-left: auto;
                  margin-right: auto;"
        >
          <v-card>
            <v-form>
              <v-card-text class="pa-8">
                <v-card flat height="100%">
                  <h3 class="mt-3 mb-5 mb-3 ml-1">
                    {{ $t('description') }}
                  </h3>

                  <v-row no-gutters>
                    <v-col cols="12" md="6" sm="12">
                      <h5
                        class="more-light-text medium-text  mb-3 	d-sm-none d-md-block d-md-flex flex-end"
                      >
                        {{ $t('max_20_char') }}
                      </h5>
                      <v-text-field
                        v-model="setupData.shortIntro"
                        placeholder="Type a short description. Eg. Wolf, Ex-Villain"
                        outlined
                        required
                        hide-details="auto"
                        :rules="rules.description"
                      />
                      <h5
                        class="d-flex more-light-text medium-text  mt-3 d-md-none flex-end"
                      >
                        {{ $t('max_20_char') }}
                      </h5>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card flat height="100%" class="pt-10">
                  <h3 class="mt-3 mb-5 mb-3 ml-1">
                    {{ $t('profile_welcome_message') }}
                  </h3>

                  <v-row no-gutters>
                    <v-col cols="12">
                      <h5
                        class="more-light-text medium-text  mb-3  d-sm-none d-md-block d-md-flex flex-end"
                      >
                        {{ $t('max_480_char') }}
                      </h5>
                      <v-textarea
                        v-model="setupData.longIntro"
                        auto-grow
                        outlined
                        rows="2"
                        row-height="60"
                        placeholder="Write your welcome message"
                        required
                        hide-details="auto"
                        :rules="rules.welcomeMessage"
                      />
                      <h5
                        class="d-flex more-light-text medium-text  d-md-none  mt-3 flex-end"
                      >
                        {{ $t('max_480_char') }}
                      </h5>
                    </v-col>
                  </v-row>
                </v-card>
              </v-card-text>
            </v-form>
          </v-card>
          <v-btn
            class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary mt-5"
            :loading="loading"
            color="primary float-right no-shadow"
            small
            @click="updateUserDescripition()"
          >
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="toggleBtnText === 'tags'" class="mb-10">
        <v-col
          cols="md-8"
          style=" display: block;
                  margin-left: auto;
                  margin-right: auto;"
        >
          <v-card-text class="pa-0">
            <v-card color="white" class="pa-8" height="100%" flat>
              <PageTitle :type="'style1'" :heading="$t('how_do_you_plan')" />
              <v-input
                class="px-4"
                hidden
                :rules="[
                  () =>
                    Boolean(selectedCategories.length > 0) ||
                    'Select at least one.',
                ]"
              />
              <v-row v-for="(category, n) in categories" :key="n">
                <v-checkbox
                  v-model="selectedCategories"
                  class="border-8 rounded-4 mt-0 pa-1"
                  :label="category"
                  :value="category"
                />
              </v-row>
            </v-card>
          </v-card-text>
          <v-card-text class="pa-0">
            <v-input
              class="mb-3 error-text"
              hidden
              :rules="[
                () =>
                  Boolean(selectedTags.length > 2) || 'Select at least three.',
              ]"
            />
            <v-card class="mb-10 overflow-y-auto pa-3" flat>
              <v-row no-gutters>
                <v-col
                  v-for="(accountTag, n) in accountTags"
                  :key="n"
                  cols="12"
                  sm="12"
                  xs="12"
                  md="3"
                  class="px-4"
                >
                  <v-checkbox
                    v-model="selectedTags"
                    :label="accountTag"
                    :value="accountTag"
                    hide-details
                    :class="{
                      'white-label': selectedTags.includes(accountTag),
                    }"
                    :color="
                      selectedTags.includes(accountTag) ? 'white' : 'primary'
                    "
                    :background-color="
                      selectedTags.includes(accountTag) ? 'primary' : null
                    "
                    on-icon="mdi-checkbox-marked-outline"
                    class="rounded-4 pa-1"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
          <v-btn
            class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary mt-5"
            :loading="loading"
            color="primary float-right no-shadow"
            small
            @click="setTalentArea()"
          >
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="toggleBtnText === 'media'">
        <v-col cols="12">
          <h3>Video</h3>
          <WelcomeVideo
            :setup-data="profileVideoStep"
            :video-upload-type.sync="profileVideoStep.uploadType"
            :video-file.sync="profileVideoStep.videoFile"
            :video-file-url.sync="profileVideoStep.videoFileUrl"
            :video-base.sync="profileVideoStep.videoBase"
          />
          <v-btn
            class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary mt-5"
            :loading="loading"
            color="primary float-left ml-3 no-shadow"
            small
            @click="updateProfileVideo()"
          >
            {{ $t('save') }}
          </v-btn>
          <br />
          <br />
          <br />
          <h3 class="mt-5">
            Photo
          </h3>
          <v-card-text class="pa-0">
            <v-card flat height="100%" class="pa-8">
              <v-row no-gutters align="center">
                <v-flex xs12>
                  <AlertBox :message="picFileTypeError" class="ma-1 mb-2" />
                </v-flex>
                <v-flex xs12 md2 mb-5>
                  <v-card
                    v-if="!cameraStart"
                    flat
                    rounded="lg"
                    color="dark_grey"
                    height="240"
                    width="240"
                    class="pa-16"
                  >
                    <v-btn
                      v-if="!cameraStart"
                      class="upload-btn buttonupload"
                      depressed
                      color="primary"
                      @click="onUpload()"
                    >
                      {{ $t('upload_profile') }}
                    </v-btn>

                    <input
                      ref="file"
                      hidden
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      @change="handleFileImageUpload()"
                    />

                    <v-btn
                      v-if="!cameraStart"
                      color="white"
                      background-color="white"
                      plain
                      class="upload-btn-1"
                      @click="onStart()"
                    >
                      {{ $t('take_photo') }}
                    </v-btn>
                  </v-card>
                  <vue-web-cam
                    v-show="cameraStart"
                    ref="webcam"
                    width="230"
                    height="240"
                    :device-id="deviceId"
                    @error="onError"
                    @cameras="onCameras"
                  />
                  <v-btn
                    v-if="cameraStart"
                    class="d-block no-shadow"
                    depressed
                    color="primary"
                    @click="onCapture()"
                  >
                    {{ $t('capture_photo') }}
                  </v-btn>
                  <v-btn
                    v-if="cameraStart"
                    class="no-shadow mt-sm-5"
                    depressed
                    color="primary"
                    @click="onTransfer()"
                  >
                    {{ $t('upload_profile') }}
                  </v-btn>
                </v-flex>
                <v-flex xs12 md2 mb-5>
                  <v-card
                    flat
                    rounded="lg"
                    color="dark_grey"
                    height="240"
                    width="220"
                    class="ma-2"
                    :img="profilePhoto"
                  >
                    <v-flex
                      xs12
                      sm12
                      md3
                      align-self-end
                      class="pa-2 pb-6 text-right deletebtn"
                    >
                      <v-btn
                        color="white"
                        small
                        height="30"
                        depressed
                        @click="removeProfilImage()"
                      >
                        <v-icon center>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </v-flex>
                  </v-card>
                  <h4 class="text-left primarytext">Primary Photo</h4>
                </v-flex>
                <v-card
                  v-for="(profilePicUrl, index) in profilePicUrls"
                  :key="index"
                  flat
                  rounded="lg"
                  color="dark_grey"
                  height="240"
                  width="240"
                  class="ma-2 mb-8"
                  :img="
                    profilePicUrl.image ? profilePicUrl.image : profilePicUrl
                  "
                >
                  <v-list
                    xs12
                    sm12
                    md3
                    align-self-end
                    class="pa-2 pb-6 text-right deletebtn"
                  >
                    <v-btn
                      color="transparent"
                      small
                      height="30"
                      depressed
                      @click="swapImage(profilePicUrl.id, false)"
                    >
                      <v-icon center>
                        mdi-content-copy
                      </v-icon>
                    </v-btn>
                    <v-btn
                      color="transparent"
                      small
                      height="30"
                      depressed
                      @click="removeImage(index, profilePicUrl.id)"
                    >
                      <v-icon center>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-list>
                </v-card>
              </v-row>
            </v-card>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//Components
import NotificationButton from '@/components/generic/NotificationButton.vue'
import MobileNavBar from '@/components/generic/MobileNavBar.vue'
import NavBarList from '@/pages/talent/dashboard/NavBarList.vue'
import { WebCam } from 'vue-web-cam'
import { DELETE_TALENT_GALLERY } from '@/graphql/account'
// import ProfilePlanning from '@/components/talent-setup/ProfilePlanning.vue'
import WelcomeVideo from '@/components/talent-setup/WelcomeVideo.vue'
// import ProfilePhoto from '@/components/talent-setup/ProfilePhoto.vue';
// import AttachPhotos from '@/components/talent-setup/AttachPhotos.vue';
import {
  UPDATE_TALENT_INTRO,
  UPDATE_PROFILE_VIDEO,
  UPLOAD_BASE64_FILE,
  UPDATE_PROFILE_IMG,
  UPDATE_TALENT_GALLERY,
  SET_TALENT_AREA,
  REMOVE_TALENT_AREA,
  SWAP_PRIMARY_SECONDARY_PHOTO,
} from '@/graphql/account'
import { PROFILE } from '@/graphql/talent'
//Unitl
import { rules } from '@/utils/validation'
import { genres } from '@/utils/static'
import PageTitle from '@/components/talent-setup/PageTitle.vue'
import { GET_TALENT_AREA } from '@/graphql/master'
import { set } from 'date-fns'
// import Tags from '@/components/talent-setup/Tags.vue'
export default {
  components: {
    //  Tags,
    NotificationButton,
    MobileNavBar,
    // ProfilePlanning,
    'vue-web-cam': WebCam,
    NavBarList,
    WelcomeVideo,
    PageTitle,
    // ProfilePhoto,
    // AttachPhotos
  },
  data() {
    return {
      rules,
      sidebarMenu: true,
      loading: false,
      toggleBtnText: 'description',
      setupData: {
        longIntro: '',
        shortIntro: '',
      },
      accountTags: [],
      selectedTags: [],
      parentTags: [],
      selectedCategories: [],
      categories: [],
      profileImgStep: {
        imageFile: null,
        imageFileUrl: null,
        uploadType: null,
        imageBase: null,
      },
      profileGalleryStep: {
        imageFile: [],
        imageFileUrl: [],
        uploadedImages: [],
      },
      parentDetails: [],
      profilePhoto: null,
      profilePicUrls: [],
      profilePicUrlss: [],
      profilePics: [],
      uploadedPics: [],
      icon: null,
      // accountTags: genres,
      // selectedTags: [],
      oldselectedTags: [],
      profileVideoStep: {
        videoFile: null,
        videoFileUrl: null,
        videoBase: null,
        uploadType: 'object',
      },
      talentAreaStep: {
        talentarea: [],
      },
      profilePicUrl: null,
      profilePic: null,
      picFileTypeError: null,
      deviceId: '',
      cameras: [],
      cameraStart: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'jwt', 'profileImage']),
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini
    },
  },
  beforeMount() {
    this.icon = '/images/icon/talent/menu.png'
    if(this.$device.mobile) this.sidebarMenu = false;
  },
  updated() {},
  mounted() {
    this.loadTags().then(this.getProfile)
    // TODO: this might need to be uncommented?
    // this.setTalentArea()
  },
  methods: {
    /**
     * Logout
     */
    async logout() {
      await this.$store.dispatch('logout')
      window.location = '/'
    },
    async parentTagListData(category) {
      this.parentDetails = category
      this.childTags(category)
    },
    async loadTags() {
      const {
        data: { getTalentArea },
      } = await this.$apollo.query({
        query: GET_TALENT_AREA,
        variables: {
          topLevel: true,
        },
      })
      this.categories = getTalentArea
        .filter(x => x.level === 1)
        .map(x => x.name)
      this.accountTags = getTalentArea
        .filter(x => x.level === 2)
        .map(x => x.name)
    },
    // },
    async getProfile() {
      this.loading = true
      const {
        data: { getBookingPageInfo },
      } = await this.$apollo.query({
        query: PROFILE,
        variables: {
          username: this.user.username,
        },
      })
      if (getBookingPageInfo.success) {
        this.loading = false
        this.profilePhoto = getBookingPageInfo.profile.user.profileImg
        const newSelectedCategories = getBookingPageInfo.profile.areas
          .filter(x => {
            return this.categories.includes(x.name)
          })
          .map(x => x.name)
        this.selectedCategories = newSelectedCategories
        const newSelectedTags = getBookingPageInfo.profile.areas
          .filter(x => {
            return this.accountTags.includes(x.name)
          })
          .map(x => x.name)
        this.selectedTags = newSelectedTags
        this.setupData.shortIntro = getBookingPageInfo.profile.shortIntro
        this.setupData.longIntro = getBookingPageInfo.profile.longIntro
        for (let images of getBookingPageInfo.gallery) {
          this.profilePicUrls.push(images)
        }
      }
    },
    async removeProfilImage() {
      if (this.profilePicUrls.length != 0) {
        const id = this.profilePicUrls[0].id
        this.profilePhoto = this.profilePicUrls[0].image
        this.profilePicUrls.splice(this.profilePicUrls[0], 1)
        await this.$store.commit('setprofileImage', this.profilePhoto)
        this.swapImage(id, true)
      } else {
        this.profilePhoto = null
      }
    },
    removeImage(imageIndex, id) {
      this.profilePicUrls = this.profilePicUrls.filter(
        (profilePicUrl, index) => index !== imageIndex
      )
      this.profilePics = this.profilePics.filter(
        (profilePicUrl, index) => index !== imageIndex
      )
      if (this.uploadedPics[imageIndex]) {
        this.deleteProfileGallery(this.uploadedPics[imageIndex].id)
        this.uploadedPics = this.uploadedPics.filter(
          (upload, index) => index !== imageIndex
        )
      }
      if (id) {
        this.deleteProfileGallery(id)
        this.uploadedPics = this.uploadedPics.filter(
          (upload, index) => index !== imageIndex
        )
      }

      if (!this.profilePicUrls.length) {
        this.$refs.file.value = ''
        this.profilePicUrls = []
        this.profilePics = []
        this.uploadedPics = []
      }

      // if (!this.profilePics.length) {
      //   this.$refs.file.value = ''
      //   this.profilePicUrls = []
      //   this.profilePics = []
      //   this.uploadedPics = []
      // }
      this.$emit('update:image-gallery', this.profilePics)

      this.$emit('update:image-file-url', this.profilePicUrls)
      this.$emit('update:image-uploaded-images', this.uploadedPics)
    },
    async deleteProfileGallery(id) {
      try {
        const {
          data: { deleteTalentGallery },
        } = await this.$apollo.mutate({
          mutation: DELETE_TALENT_GALLERY,
          variables: {
            galleryInput: {
              email: this.user.email,
              fileid: id,
              accesstoken: this.jwt,
            },
          },
        })

        if (deleteTalentGallery.success) {
          this.$toasted.success('image removed')
        } else {
          this.$toasted.success('Something Went Wrong')
        }
      } catch (e) {
        console.error(e) // eslint-disable-line
      }
    },
    async updateUserDescripition() {
      const {
        data: { updateTalent },
      } = await this.$apollo.mutate({
        mutation: UPDATE_TALENT_INTRO,
        variables: {
          talentData: {
            email: this.user.email,
            accesstoken: this.jwt,
            longIntro: this.setupData.longIntro,
            shortIntro: this.setupData.shortIntro,
          },
        },
      })
      if (updateTalent.success) {
        this.$toasted.success('Your changes have been made successfully')
      } else {
        this.$toasted.error('Something went Wrong')
      }
    },
    async swapImage(id, status) {
      const {
        data: { swapPrimarySecondaryPhoto },
      } = await this.$apollo.mutate({
        mutation: SWAP_PRIMARY_SECONDARY_PHOTO,
        variables: {
          swapPhotoData: {
            galleryId: id,
            email: this.user.email,
            accesstoken: this.jwt,
            galleryRemove: status,
          },
        },
      })
      if (swapPrimarySecondaryPhoto.success) {
        this.profilePhoto = null
        this.profilePhoto = swapPrimarySecondaryPhoto.talent.profileImg
        await this.$store.commit(
          'setprofileImage',
          swapPrimarySecondaryPhoto.talent.profileImg
        )
        this.profilePicUrls = swapPrimarySecondaryPhoto.gallery
      }
    },
    async updateProfileImage() {
      this.error = null
      if (this.profileImgStep.imageFile != null) {
        this.loading = true
        try {
          const {
            data: { updateProfileImage },
          } = await this.$apollo.mutate({
            mutation: UPDATE_PROFILE_IMG,
            variables: {
              imageFile: this.profileImgStep.imageFile,
              tokenData: { email: this.user.email, accesstoken: this.jwt },
            },
          })

          // Set User ProfileImage in vuex
          if (updateProfileImage.success) {
            this.profileImgStep.imageFileUrl = updateProfileImage.url
            await this.$store.commit('setprofileImage', updateProfileImage.url)
            this.profilePic = null
            this.$toasted.success('Your changes have been made successfully')
            this.loading = false
          } else {
            this.error = this.$t(updateProfileImage.error)
            this.loading = false
          }
        } catch (e) {
          this.error = e
          this.loading = false
          console.error(e) // eslint-disable-line
        }
      }
    },
    /**
     * Update Profile Image base64
     */
    async updateProfileImageBase64(option) {
      this.error = null
      this.loading = true
      try {
        const {
          data: { uploadBase64File },
        } = await this.$apollo.mutate({
          mutation: UPLOAD_BASE64_FILE,
          variables: {
            baseData: {
              base64: this.profileImgStep.imageFileUrl,
              email: this.user.email,
              accesstoken: this.jwt,
              contentType: 'image/png',
              updateOption: option,
            },
          },
        })

        // Set User ProfileImage in vuex
        if (uploadBase64File.success) {
          this.profileImgStep.imageFileUrl = uploadBase64File.url
          this.loading = false
          this.$toasted.success('Your changes have been made successfully')
          if ((option = 'profile')) {
            await this.$store.commit('setprofileImage', uploadBase64File.url)
          }
        } else {
          this.error = this.$t(updateProfileImage.error)
          this.loading = false
        }
      } catch (e) {
        this.error = e
        this.loading = false
        console.error(e) // eslint-disable-line
      }
    },
    /**
     * Update Profile Video
     */
    async updateProfileVideo() {
      this.error = null
      if (this.profileVideoStep.videoFile) {
        this.loading = true
        try {
          const {
            data: { updateProfileVideo },
          } = await this.$apollo.mutate({
            mutation: UPDATE_PROFILE_VIDEO,
            variables: {
              videoFile: this.profileVideoStep.videoFile,
              tokenData: {
                email: this.user.email,
                accesstoken: this.jwt,
              },
            },
          })

          // Set User ProfileImage in vuex
          if (updateProfileVideo.success) {
            this.profileVideoStep.videoFileUrl = updateProfileVideo.url
            this.user.profileVideo = updateProfileVideo.url
            this.loading = false
            this.$toasted.success('Your changes have been made successfully')
          } else {
            this.error = this.$t(updateProfileVideo.error)
            this.loading = false
          }
        } catch (e) {
          this.error = e
          this.loading = false
          console.error(e) // eslint-disable-line
        }
      } else {
        this.updateProfileVideoBase64()
      }
    },
    /**
     * Update Profile Video base64
     */
    async updateProfileVideoBase64() {
      this.error = null
      if (this.profileVideoStep.videoBase) {
        this.loading = true
        try {
          const {
            data: { uploadBase64File },
          } = await this.$apollo.mutate({
            mutation: UPLOAD_BASE64_FILE,
            variables: {
              baseData: {
                base64: this.profileVideoStep.videoBase,
                email: this.user.email,
                accesstoken: this.jwt,
                contentType: 'video/mp4',
                updateOption: 'portfolio',
              },
            },
          })

          // Set User ProfileImage in vuex
          if (uploadBase64File.success) {
            this.profileVideoStep.videoFileUrl = uploadBase64File.url
            this.user.profileVideo = uploadBase64File.url
            this.loading = false
            this.$toasted.success('Your changes have been made successfully')
          } else {
            this.error = this.$t(uploadBase64File.error)
            this.loading = false
          }
        } catch (e) {
          this.error = e
          this.loading = false
          console.error(e) // eslint-disable-line
        }
      }
    },
    async updateProfileGallery() {
      this.error = null
      // this.updateProfileImage()
      if (this.profilePics.length) {
        this.loading = true
        try {
          const {
            data: { uploadTalentGallery },
          } = await this.$apollo.mutate({
            mutation: UPDATE_TALENT_GALLERY,
            variables: {
              galleryInput: {
                imageFile: [...this.profilePics],
                accesstoken: this.jwt,
                email: this.user.email,
              },
            },
          })
          if (uploadTalentGallery.success) {
            this.profilePicUrls = uploadTalentGallery.gallery
            this.loading = false
            this.profileGalleryStep.uploadedImages = uploadTalentGallery.gallery
            this.profileGalleryStep.imageFile = []
            this.$toasted.success('Your changes have been made successfully')
          } else {
            this.error = this.$t(uploadTalentGallery.error)
            this.loading = false
          }
        } catch (e) {
          this.error = e
          this.loading = false
          console.error(e) // eslint-disable-line
        }
      }
    },
    onCameras(cameras) {
      this.cameras = cameras
    },
    onTransfer() {
      this.onStop()
      this.onUpload()
    },
    onUpload() {
      this.$refs.file.click()
    },
    onCapture() {
      var captureImage = this.$refs.webcam.capture()
      this.onStop()
      this.showRemoveAvatarButton = true
      if (this.profilePhoto == null) {
        this.profilePhoto = captureImage
        this.profileImgStep.imageFileUrl = this.profilePhoto.split(',')[1]
        this.$emit('update:image-file', null)
        this.updateProfileImageBase64('profile')
      } else {
        if (this.profilePicUrls.length < 6) {
          this.profileImgStep.imageFileUrl = captureImage.split(',')[1]
          this.updateProfileImageBase64('gallery')
        } else {
          this.$toasted.error('You can upload upto 6 images')
        }
      }
    },
    onError(error) {
      console.log('On Error Event', error)
    },
    onStop() {
      this.$refs.webcam.stop()
      this.cameraStart = false
    },
    onStart() {
      if (this.cameras[0] == undefined) {
        this.showError('camera_not_found')
        return
      }
      this.deviceId = this.cameras[0].deviceId
      this.cameraStart = true
      this.$refs.webcam.start()
      this.$emit('update:image-upload-type', 'base64')
    },
    handleFileImageUpload() {
      this.profilePics = []
      if (this.profilePhoto != null) {
        if (this.profilePicUrls.length < 6) {
          this.picFileTypeError = null
          /*
        Set the local file variable to what the user has selected.
      */
          const profilePic = this.$refs.file.files[0]
          this.$refs.file.value = null
          /*
        Check to see if the file is not empty.
        Ensure the file is an image file.
      */
          if (profilePic && /\.(jpe?g|png)$/i.test(profilePic.name)) {
            if (profilePic.size > 6000000) {
              this.showError('file_size_msg')
              return
            }
            this.profilePics.push(profilePic)

            /*
          Initialize a File Reader object
        */
            const reader = new FileReader()

            /*
          Add an event listener to the reader that when the file
          has been loaded, we flag the show preview as true and set the
          image to be what was read from the reader.
        */
            reader.addEventListener(
              'load',
              () => {
                this.showRemoveAvatarButton = true
                // this.profilePicUrls.push(reader.result)
                this.updateProfileGallery()
                this.$emit('update:image-file-url', this.profilePicUrls)
              },
              false
            )

            /*
        Fire the readAsDataURL method which will read the file in and
        upon completion fire a 'load' event which we will listen to and
        display the image in the preview.
        */
            reader.readAsDataURL(profilePic)
            this.$emit('update:image-gallery', this.profilePics)
          } else {
            this.showError('invalid_file_type')
          }
        } else {
          this.$toasted.error('You can upload upto 6 images')
        }
      } else {
        this.picFileTypeError = null

        /*
        Set the local file variable to what the user has selected.
      */
        this.profilePic = this.$refs.file.files[0]
        this.$refs.file.value = null

        /*
        Check to see if the file is not empty.
        Ensure the file is an image file.
      */
        if (this.profilePic && /\.(jpe?g|png)$/i.test(this.profilePic.name)) {
          if (this.profilePic.size > 6000000) {
            this.showError('file_size_msg')
            return
          }

          /*
          Initialize a File Reader object
        */
          const reader = new FileReader()

          /*
          Add an event listener to the reader that when the file
          has been loaded, we flag the show preview as true and set the
          image to be what was read from the reader.
        */
          reader.addEventListener(
            'load',
            () => {
              this.showRemoveAvatarButton = true
              this.profilePhoto = reader.result
              this.$emit('update:image-file-url', this.profilePhoto)
              this.updateProfileImage()
              this.$emit('update:image-upload-type', 'object')
            },
            false
          )

          /*
        Fire the readAsDataURL method which will read the file in and
        upon completion fire a 'load' event which we will listen to and
        display the image in the preview.
        */
          reader.readAsDataURL(this.profilePic)
          this.$emit('update:image-base', null)
          this.$emit('update:image-file', this.profilePic)
          this.profileImgStep.imageFile = this.profilePic
        } else {
          this.showError('invalid_file_type')
        }
      }
    },

    showError(message) {
      this.picFileTypeError = i18n.t(message)
      this.$refs.file.value = ''
      this.$nextTick(() => {
        this.timeOut = setTimeout(() => {
          this.picFileTypeError = null
        }, 3000)
      })
    },
    /**
     * Set Talent Area
     */
    setTalentArea() {
      if (this.selectedTags.length < 3) {
        this.$toasted.error(this.$t('Select_at_least_one'))
      } else {
        this.removeTalentArea()
      }
    },
    async removeTalentArea() {
      this.error = null
      this.loading = true
      try {
        const {
          data: { removeTalentArea },
        } = await this.$apollo.mutate({
          mutation: REMOVE_TALENT_AREA,
          variables: {
            talentareaData: {
              email: this.user.email,
              accesstoken: this.jwt,
              talentarea: this.oldselectedTags,
            },
          },
        })
        this.setTalentArea1()
        // Set User Intro in vuex
        if (removeTalentArea.success) {
          this.loading = false
          this.$toasted.success('Your changes have been made successfully')
        } else {
          this.error = this.$t(removeTalentArea.error)
          this.loading = false
        }
      } catch (e) {
        this.error = e
        this.loading = false
        console.error(e) // eslint-disable-line
      }
    },
    async setTalentArea1() {
      this.error = null
      this.loading = true
      try {
        const {
          data: { setTalentArea },
        } = await this.$apollo.mutate({
          mutation: SET_TALENT_AREA,
          variables: {
            talentareaData: {
              email: this.user.email,
              accesstoken: this.jwt,
              talentarea: [
                ...new Set([...this.selectedTags, ...this.selectedCategories]),
              ],
            },
          },
        })

        // Set User Intro in vuex
        if (setTalentArea.success) {
          this.loading = false
        } else {
          this.error = this.$t(setTalentArea.error)
          this.loading = false
        }
      } catch (e) {
        this.error = e
        this.loading = false
        console.error(e) // eslint-disable-line
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.flex.xs12.md2.mb-5 {
  max-width: 100%;
}
.availability {
  display: flex;
  align-items: center;
}
.deletebtn {
  position: absolute;
  bottom: -57px;
  right: 13px;
  background: transparent;
}
.deletebtn button {
  background: transparent;
}
.availability-toggler {
  min-width: 170px;
  background: #fff;
}

.availability-toggler .v-btn--active::before {
  background: #ffb421 !important;
  color: #fff !important;
  opacity: 1;
  border-radius: 5px;
}
.white-label label {
  color: $white !important;
}
.availability-toggler .v-btn--active {
  color: #fff !important;
}

.upload-btn.buttonupload {
  margin-bottom: 17px;
  margin-left: -24px;
}

.upload-btn-1 {
  position: unset;

  width: 155px !important;

  margin-left: -23px !important;
  margin-right: auto;
}
.session-card-section {
  border: none !important;
}
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgb(0 0 0 / 0%) !important;
}
</style>
<style>
.row.px-15.mt-3.desktop-view.align-center {
  display: none;
}
/* .v-application .text-h5 {
  display: none !important;
} */
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
@media only screen and (max-width: 959px) {
  nav.dektop-view.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--floating.v-navigation-drawer--open.theme--light {
    display: none;
  }
  .mobile-view {
    display: block;
    position: absolute;
    left: -42px;
  }
  .v-main {
    padding: 56px 0px 0px 0px !important;
  }
}
@media only screen and (max-width: 600px) {
  .profileicons {
    margin-top: 0%;
  }
  .search-box.v-card.v-sheet.theme--light {
    display: none;
  }
  nav.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--floating.v-navigation-drawer--mini-variant.v-navigation-drawer--open.theme--light {
    display: none;
  }
  .v-image.v-responsive.elevation-6.theme--light {
    display: block;
  }
  .desktop-view {
    display: none;
  }
  /* #app
    .v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
    min-width: 90% !important;
    max-width: 94% !important;
  } */
}
</style>
